$forground: white;
$background: transparent;
$min-height: 675px;

.Home {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: $min-height;
}

.Home .heroContainer {
    position: absolute;
    display: flex;
    top: 0px;
    width: 100%;
    height: 100vh;
    min-height: $min-height;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}
.Home .heroContainer .hero {
    position: absolute;
    width: 100%;
    height: 100vh;
    min-height: $min-height;
    background-color: black;
    background-image: url("../../assets/wood-hero.jpg"); // @timmossholder
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1.2);
}

.Home .heroContainer .centerContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.Home .heroContainer .centerContainer .text {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    margin-bottom: 50px;
}
.Home .heroContainer .centerContainer .buttons {
    width: 100%;
    text-align: center;
}
.Home .heroContainer .centerContainer .buttons .button {
    margin: 0px 20px 30px 20px;
}

.Home .heroContainer .see-more-button-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    right: 50%;
    margin-left: -75px;
}
.Home .heroContainer .button {
    display: inline-block;
    width: 150px;
    padding: 15px 0px;
    text-align: center;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    border: 2px solid white;
    background-color: rgba(169,169,169,0.35);
    color: white;
    cursor: pointer;
}
.Home .heroContainer .button:hover {
    color: black;
    background: white;
}
@media only screen and (max-width: 680px) {
    .Home .heroContainer .centerContainer .text {
        font-size: 40px;
    }
}

.Home .icons {
    position: absolute;
    bottom: 20px;
    right: 0px;
    width: 200px;
    text-align: center;
    cursor: pointer;
}
.Home .fa {
    width: 45px;
    height: 45px;
    margin: 0px 2%;
    padding-top: 8px;
    font-size: 27px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    border: 2px $forground solid;
    background: transparent;
    color: $forground;
}
.Home .fa:hover {
    background: $forground;
    color: black;
}

@media only screen and (max-width: 480px) {
    .Home .heroContainer .see-more-button-container {
        bottom: 80px;
    }
    .Home .icons {
        left: 50%;
        right: 50%;
        margin-left: -100px;
    }
}
