$reactiveButtonHeight: 40px;

.Nav {
    position: fixed;
    width: 100%;
    height: 80px;
    transition: 0.2s;
    z-index: 5;
}
.Nav .centerer {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.Nav .buttonGroup {
    position: absolute;
    right: 3%;
    height: 100%;
}
.Nav .buttonGroup .button {
    display: inline-block;
    vertical-align: middle;
    height: 60%;
    padding: 0px 1em;
    cursor: pointer;
    border-radius: 5px;
    color: white;
}
.Nav .buttonGroup .button .button-text {
    display: inline-block;
    font-size: 15px;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 3px;
    font-weight: 800;
}
.Nav .buttonGroup .button:hover {
    color: gold;
}
.Nav .buttonGroup .activeBtn {
    color: black;
    background: gold;
}
.Nav .buttonGroup .activeBtn:hover {
    color: black;
}


.Nav .responsiveMenu {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
}

.Nav .responsiveMenu .menu-button {
    position: absolute;
    top: 10%;
    right: 3%;
    width: 35px;
    height: 35px;
    z-index: 5;
}
.Nav .responsiveMenu .menu-button .bar1, .bar2, .bar3 {
    width: 35px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
}
.Nav .responsiveMenu .menu-button .closeBar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}
.Nav .responsiveMenu .menu-button .closeBar2 {
    opacity: 0;
}
.Nav .responsiveMenu .menu-button .closeBar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -4px);
    transform: rotate(45deg) translate(-6px, -4px);
}

.Nav .responsiveMenu .button {
    position: absolute;
    vertical-align: middle;
    width: 100%;
    height: $reactiveButtonHeight;
    top: -60px;
    cursor: pointer;
    background: black;
    color: white;
    text-align: center;
}
.Nav .responsiveMenu .openAboutBtn {
    top: $reactiveButtonHeight*0;
    transition: top 0.3s;
    z-index: 4;
}
.Nav .responsiveMenu .closeAboutBtn {
    top: -$reactiveButtonHeight;
    transition: top 0.6s;
    z-index: 4;
}

.Nav .responsiveMenu .openSkillsBtn {
    top: $reactiveButtonHeight*1;
    transition: top 0.4s;
    z-index: 3;
}
.Nav .responsiveMenu .closeSkillsBtn {
    top: -$reactiveButtonHeight;
    transition: top 0.5s;
    z-index: 3;
}

.Nav .responsiveMenu .openProjectsBtn {
    top: $reactiveButtonHeight*2;
    transition: top 0.5s;
    z-index: 2;
}
.Nav .responsiveMenu .closeProjectsBtn {
    top: -$reactiveButtonHeight;
    transition: top 0.4s;
    z-index: 2;
}

.Nav .responsiveMenu .openContactBtn {
    top: $reactiveButtonHeight*3;
    transition: top 0.6s;
    z-index: 1;
}
.Nav .responsiveMenu .closeContactBtn {
    top: -$reactiveButtonHeight;
    transition: top 0.3s;
    z-index: 1;
}

.Nav .responsiveMenu .button .button-text {
    display: inline-block;
    font-size: 15px;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 3px;
}
.Nav .responsiveMenu .button:hover {
    color: gold;
}
.Nav .responsiveMenu .activeBtn {
    color: black;
    background: gold;
}
.Nav .responsiveMenu .activeBtn:hover {
    color: white;
}

@media only screen and (max-width: 680px) {
    .Nav .buttonGroup {
        display: none;
    }
    .Nav .responsiveMenu {
        display: inline;
    }
}