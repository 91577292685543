$forground: indigo;
$background: white;

.About {
    background: $background;
}

.About .icons {
    width: 100%;
    max-width: 400px;
    margin: 0px auto 30px auto;
    text-align: center;
    cursor: pointer;
}
.About .fa {
    width: 45px;
    height: 45px;
    margin: 0px 3%;
    padding-top: 5px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    border: 2px $forground solid;
    background: $background;
    color: $forground;
    visibility: hidden;
}
.About .fa:hover {
    background: $forground;
    color: $background;
}

.About .container-fluid {
    max-width: 1000px;
}

.About .container-fluid .text {
    text-align: center;
    margin-bottom: 20px;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    visibility: hidden;
}
