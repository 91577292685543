$forground: indigo;
$background: #f7f7f7;

.Skills {
    background: $background;
}

/*
    Skills Cards
*/
.Skills .description-section {
    margin-bottom: 50px;
}

.Skills .skillsCard {
    display: inline-block;
    width: 100%;
    max-width: 500px;
    margin: 0px 10px 70px 10px;
    padding: 0px 15px;
    text-align: center;
    color: black;
    visibility: hidden;
}
.Skills .skillsCard .image {
    width: 100px;
    margin: 0px auto 20px auto;
}
.Skills .skillsCard .header {
    width: 100%;
    margin: 0px auto 20px auto;
    font-size: 20px;
    line-height: 1.1em;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}
.Skills .skillsCard .footer {
    width: 70%;
    margin: 0px auto;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-family: 'Exo', sans-serif;
    color: gray;
}

/*
    Skill Cols
*/
.Skills .type {
    width: 100%;
    text-align: center;
}
.Skills .type .centerer {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.Skills .type .header {
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.Skills .skillRow {
    font-family: 'Montserrat', sans-serif;
}
.Skills .skillRow .group {
    display: inline-block;
    width: 300px;
    margin: 0px 10px 40px 10px;
    padding: 30px 0px;
    border-radius: 5px;
    border: 1px black solid;
    box-shadow:0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    color: white;
    background: $forground;
    cursor: default;
    visibility: hidden;
}
.Skills .skillRow .group .header {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}
.Skills .skillRow .group .info {
    margin: 0px auto;
    font-size: 15px;
    text-align: center;
}
.Skills .skillRow .group .info .item {
    width: 50%;
}