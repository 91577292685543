@import url('https://fonts.googleapis.com/css?family=Quicksand');               //font-family: 'Quicksand', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Montserrat');              //font-family: 'Montserrat', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Roboto');                  //font-family: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Exo|Fredoka+One|Roboto');  //font-family: 'Roboto', sans-serif; font-family: 'Exo', sans-serif; font-family: 'Fredoka One', cursive;
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');  //Social Media Icons

//Section
.container-fluid {
    max-width: 1200px;
}

.section {
    padding: 30px 0px 50px 0px;
    overflow: hidden;
}
.section .sectionLabelContainer {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0px auto 20px auto;
}
.section .sectionLabelContainer .sectionLabel {
    display: inline-block;
    padding: 1em 2em;
    text-align: center;
    letter-spacing: 3px;
    color: black;
    font-size: 40px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-family: 'Exo', sans-serif;
}
.section .sectionLabelContainer .sectionLabelLine {
    border-bottom: 3px orange solid;
}